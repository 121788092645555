import React from "react";
import * as PropTypes from "prop-types";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { ooContentWidth} from "../theme";
import { OoTypoLightGrey } from "./OoTypo";

const Banner = styled.div`
  background-color: ${props => props.backgroundColor};
  min-height: ${props => props.minHeight};
  padding: 3rem 1rem;
  text-align: center;
  
  display: flex; 
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
`;
const RestrictWidth = styled.div`
  max-width: ${ooContentWidth}rem;
`;

/**
 * Page banner for huge headings at the top of the page.
 * @param heading         required. The heading text as String.
 * @param subheading      optional. The subheading text as String.
 * @param backgroundColor optional. Background color of the banner as hex value in a string.
 * @param minHeight       optional. min-height of the banner as a string with unit.
 * @param extraComponent  optional. Extra component to add between heading and subheading.
 */
const PageBanner = ({ heading, subheading, backgroundColor, minHeight, extraComponent }) => {
  let subheadingTypography = null;
  let headingTypography = null;

  if (heading.length > 0) {
    headingTypography = (<Typography variant="h1" gutterBottom>{heading}</Typography>);
  }

  if (subheading.length > 0) {
    subheadingTypography = (
      <OoTypoLightGrey component="p" variant="h6">{subheading}</OoTypoLightGrey>
    );
  }

  return (
    <Banner backgroundColor={backgroundColor} minHeight={minHeight}>
      <RestrictWidth>
      {headingTypography}
      {extraComponent}
      {subheadingTypography}
      </RestrictWidth>
    </Banner>
  );
};

PageBanner.defaultProps = {
  backgroundColor: "initial",
  heading: "",
  subheading: "",
  minHeight: "15rem",
  extraComponent: null,
};
PageBanner.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  backgroundColor: PropTypes.string,
  minHeight: PropTypes.string,
  extraComponent: PropTypes.object,
};

export default PageBanner;