/**
 * Calculate the reading time from a given body (string) of text.
 * @return String `${readTime} minute read`
 */
export const readingTimeFromText = (text) => {
  const wordsPerMinute = 200;
  const noOfWords = text.split(/\s/g).length;
  const minutes = noOfWords / wordsPerMinute;
  const readTime = Math.ceil(minutes);
  return `${readTime} minute read`;
};
/**
 * Calculate the reading time from a number of words given.
 * @return String `${readTime} minute read`
 */
export const readingTimeFromWords = (words) => {
  const wordsPerMinute = 200;
  const minutes = words / wordsPerMinute;
  const readTime = Math.ceil(minutes);
  return `${readTime} minute read`;
};

const removeParentPath = path => path.split("\\").pop().split("/").pop();

export const getFileName = fileName => removeParentPath(fileName).slice(0, -3);
