import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { grey4, grey7 } from "../theme"; // light, dark

const useStyles = makeStyles({
  grey: {
    color: grey4,
  },
  lightGrey: {
    color: grey7,
  },
});

export const OoTypoGrey = (props) => {
  const { children, ...other } = props; // eslint-disable-line react/prop-types
  const classes = useStyles();
  return (
    <Typography {...other} className={classes.grey}>
      {children}
    </Typography>
  );
};

export const OoTypoLightGrey = (props) => {
  const { children, light, ...other } = props; // eslint-disable-line react/prop-types
  const classes = useStyles();
  return (
    <Typography {...other} className={classes.lightGrey}>
      {children}
    </Typography>
  );
};