import React from "react";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import * as PropTypes from "prop-types";
import { ooTextWidth, grey4 } from "../element/theme";
import thomas from "../about/visual/team-thomas-uta-avatar-192px.jpg";
import OoAvatar from "../element/graphical/OoAvatar";

const shortBioText = "Founder & Data Engineer at Oberion. Likes strategy and meaningful stories. Entrepreneur and retired Minesweeper pro player.";

const GreyTextDiv = styled.div`
  color: ${grey4};
  text-align: left;
`;
const AuthorDiv = styled.div`
    height: 100%;
    max-width: ${ooTextWidth}rem;
    padding: 0.5rem 0;
    margin: 0 auto;
    
    display: flex; 
    flex-flow: row nowrap;
    align-items: center;
`;

/**
 * Display author with a short bio text.
 */
export const AuthorBio = ({ author, datePublished, readingTime }) => {
  const postInfo = `${readingTime} · ${datePublished}`;
  return (
    <AuthorDiv>
      <OoAvatar src={thomas} alt={author} height="4rem" width="4rem"
                margin="0.5rem 1.5rem 0.5rem 0.5rem" />

      <GreyTextDiv>
        <Typography variant="subtitle1" gutterBottom>{author}</Typography>
        <Typography component="p" variant="caption" gutterBottom>{shortBioText}</Typography>
        <Typography variant="caption">{postInfo}</Typography>
      </GreyTextDiv>

    </AuthorDiv>
  );
};
AuthorBio.propTypes = {
  author: PropTypes.string.isRequired,
  datePublished: PropTypes.string.isRequired,
  readingTime: PropTypes.string.isRequired,
};

/**
 * Display author info of publication.
 */
export const AuthorOfPublication = ({ datePublished, author }) => (
  <AuthorDiv>
    <OoAvatar src={thomas} alt={author} height="3rem" width="3rem" />
    <GreyTextDiv>
      <Typography variant="body1">{author} on {datePublished}</Typography>
    </GreyTextDiv>
  </AuthorDiv>
);
AuthorOfPublication.propTypes = {
  datePublished: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
};
