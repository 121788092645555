import React from "react";
import { graphql } from "gatsby";
import * as PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import Layout from "../components/element/layout/Layout";
import PageBanner from "../components/element/page-element/PageBanner";
import LayoutSetBackground from "../components/element/layout/LayoutSetBackground";
import theme, { ooTextWidth, grey8 } from "../components/element/theme";
import LayoutColumn from "../components/element/layout/LayoutColumn";
import SEO from "../components/element/SEO";
import { AuthorBio } from "../components/blog/Author";
import { readingTimeFromWords } from "../components/blog/blogUtil";


function BlogTemplate(props) {
  const { data: { blog: { html, frontmatter, excerpt, wordCount: { words } } } } = props;
  const authorData = {
    author: frontmatter.author,
    datePublished: frontmatter.date,
    readingTime: readingTimeFromWords(words),
  };
  const seo = {
    title: `${frontmatter.title} - Oberion`,
    description: excerpt,
  };


  const pageBanner = (<PageBanner heading={frontmatter.title} />);

  return (
    <Layout>
      <SEO {...seo} />
      {pageBanner}
      <LayoutSetBackground backgroundColor={grey8} color={theme.palette.secondary.contrastText}>
        <LayoutColumn justifyContent="center"
                      textAlign="left"
                      maxWidth={ooTextWidth}>

          <AuthorBio {...authorData} />

          <Typography component="span" color="inherit" variant="body1">
            <div
              className="blog-post"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </Typography>
        </LayoutColumn>
      </LayoutSetBackground>
    </Layout>
  );
}

export const blogQuery = graphql`
  query ($slug: String!) {
    blog: markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        date(formatString: "DD MMMM YYYY")
        author
      }
      excerpt(pruneLength: 165)
      fields {slug}
      wordCount {
        words
      }
    }
  }
`;

BlogTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};
export default BlogTemplate;